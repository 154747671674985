<template>
  <div>


    <!--탑메뉴-->
    <topbar-comp></topbar-comp>
    <left-bar-comp>
      <sports-left-bar-comp></sports-left-bar-comp>
    </left-bar-comp>

    <div class="content_section">
      <div class="content">
        <sub-title>충전</sub-title>

        <div class="main_panel">

          <!--                    <apply-buttons-comp></apply-buttons-comp>-->


          <div class="re_info" v-html="infotext"></div>


          <div class="re_cont" style="margin-top: 5px">
            <div class="c1">
              입금명
            </div>
            <div class="c2"
                 style="display: flex;justify-content: flex-start;align-items: center;flex-direction: row">
              <span>{{ $store.state.userInfo.beneficiary }}</span>
              <button class="askacno" @click="questioAcNo"><i class="fa fa-question-circle"></i>간편계좌문의
              </button>
            </div>
          </div>
          <div class="re_cont" style="margin-top: 5px" v-if="coinable == sportsConst.YES">
            <div class="c1">
              코인
            </div>
            <div class="c2"
                 style="display: flex;justify-content: flex-start;align-items: center;flex-direction: row">
              <span>{{ $store.state.userInfo.beneficiary }}</span>
              <button class="coin" @click="gotocoinsite"><i class="fa fa-database"></i> 코인충전 이동
              </button>
              <button class="coin2" @click="changeCoin2SiteCash"> 코인>머니전환
              </button>
            </div>
          </div>
          <div class="re_cont">
            <div class="c1">
              금액
            </div>
            <div class="c2" style="">
              <div style="display: flex;justify-content: flex-start;align-items: center;padding: 5px 0">
                <input type="text" v-model="applyAmount" class="re_input" placeholder="충전금액"
                       @input="setComma">
                <button type="button" class="btn-re" @click="changeMoney(0)"
                        style="width: 130px;margin-left: 6px">정정하기
                </button>
              </div>
              <div style="display: flex; justify-content: flex-start;width: 100%;padding: 3px 0;flex-wrap: wrap">
                <button type="button" class="btn-re" @click="changeMoney(10000)">
                  1만원
                </button>
                <button type="button" class="btn-re" @click="changeMoney(30000)">
                  3만원
                </button>
                <button type="button" class="btn-re" @click="changeMoney(50000)">
                  5만원
                </button>
                <button type="button" class="btn-re" @click="changeMoney(100000)">
                  10만원
                </button>
                <button type="button" class="btn-re" @click="changeMoney(500000)">
                  50만원
                </button>
                <button type="button" class="btn-re" @click="changeMoney(1000000)">
                  100만원
                </button>

              </div>
            </div>
          </div>

          <div class="re_cont">
            <div class="c1">
              충전유형
            </div>
            <div class="c2bonus" style="font-weight: normal;">
              <div class="bonuspanel">
                <div style="display: flex;justify-content: flex-start;align-items: center;padding: 5px 0">
                  <input type="radio" name="bonusin" id="bonusin0"
                         :value="sportsConst.APPLY_BONUS_NOT"
                         v-model="applyCash.bonusin">
                  <span style="margin-left: 5px;"><label for="bonusin0"
                                                         style="color: red">보너스 미지급</label></span>
                </div>
              </div>
              <div class="bonuspanel">
                <div style="display: flex;justify-content: flex-start;align-items: center;padding: 5px 0">
                  <input type="radio" name="bonusin" id="bonusin1"
                         :value="sportsConst.APPLY_BONUS_YES1"
                         v-model="applyCash.bonusin">
                  <span style="margin-left: 5px">
                                                            <label for="bonusin1"
                                                                   style="color: #fff60d"> 지급{{ b1 }}</label>
                                                        </span>

                </div>
              </div>
              <!--                            <div class="bonuspanel">-->
              <!--                                <div style="display: flex;justify-content: flex-start;align-items: center;padding: 5px 0">-->
              <!--                                    <input type="radio" name="bonusin" id="bonusin1"-->
              <!--                                           :value="sportsConst.APPLY_BONUS_YES1"-->
              <!--                                           v-model="applyCash.bonusin">-->
              <!--                                    <span style="margin-left: 5px">-->
              <!--                                                            <label for="bonusin1"-->
              <!--                                                                   style="color: #fff60d"> 스포츠{{b1}}</label>-->
              <!--                                                        </span>-->

              <!--                                </div>-->
              <!--                                <div style="padding-left: 16px;color: #fd7879;">-->
              <!--                                    <p>롤링100%</p>-->
              <!--                                </div>-->
              <!--                            </div>-->
              <!--                            <div class="bonuspanel">-->
              <!--                                <div style="display: flex;justify-content: flex-start;align-items: center;padding: 5px 0">-->
              <!--                                    <input type="radio" name="bonusin" id="bonusin2"-->
              <!--                                           :value="sportsConst.APPLY_BONUS_YES2"-->
              <!--                                           v-model="applyCash.bonusin">-->
              <!--                                    <span style="margin-left: 5px">-->
              <!--                                                            <label for="bonusin2"-->
              <!--                                                                   style="color: skyblue">미니게임 {{b2}}</label>-->
              <!--                                                        </span>-->
              <!--                                </div>-->
              <!--                                <div style="padding-left: 16px;color: #fd7879;">-->
              <!--                                    <p>롤링200%</p>-->
              <!--                                </div>-->
              <!--                            </div>-->
              <!--                            <div class="bonuspanel">-->
              <!--                                <div style="display: flex;justify-content: flex-start;align-items: center;padding: 5px 0">-->
              <!--                                    <input type="radio" name="bonusin" id="bonusin3"-->
              <!--                                           :value="sportsConst.APPLY_BONUS_YES3"-->
              <!--                                           v-model="applyCash.bonusin">-->
              <!--                                    <span style="margin-left: 5px">-->
              <!--                                                            <label for="bonusin3"-->
              <!--                                                                   style="color: gainsboro">카지노 {{b3}}</label>-->
              <!--                                                        </span>-->
              <!--                                </div>-->
              <!--                                <div style="padding-left: 16px;color: #fd7879;">-->
              <!--                                    <p>롤링300%</p>-->
              <!--                                </div>-->
              <!--                            </div>-->
              <!--                            <div class="bonuspanel">-->
              <!--                                <div style="display: flex;justify-content: flex-start;align-items: center;padding: 5px 0">-->
              <!--                                    <input type="radio" name="bonusin" id="bonusin4"-->
              <!--                                           :value="sportsConst.APPLY_BONUS_YES4"-->
              <!--                                           v-model="applyCash.bonusin">-->
              <!--                                    <span style="margin-left: 5px">-->
              <!--                                                            <label for="bonusin4"-->
              <!--                                                                   style="color: gainsboro">슬롯 {{b4}}</label>-->
              <!--                                                        </span>-->
              <!--                                </div>-->
              <!--                                <div style="padding-left: 16px;color: #fd7879;">-->
              <!--                                    <p>롤링200%</p>-->
              <!--                                </div>-->
              <!--                            </div>-->

            </div>
          </div>
          <div class="re_cont">
            <div class="c1">
              <span style="color: red">*주의*</span>
            </div>
            <div class="c2" style="font-size: 11px;font-weight: normal;padding: 5px 0;color: red">
              <p>* 이용게임에 따라 롤링규정은 상이하오니 공지사항 참고 부탁드립니다.</p>
            </div>
          </div>

          <div class="rec_buttons" style="">
            <button class="b1" @click="recharge(0)"><i class="fa fa-plus-square"></i> 충전신청
            </button>
          </div>
        </div>
        <div class="re_history">
          <table class="utable" cellpadding="0" cellspacing="0" border="0">
            <colgroup>
              <col width="27%">
              <col width="27%">
              <col width="27%">
              <col width="15%">
            </colgroup>
            <thead>
            <tr>
              <th>일자</th>
              <th>금액</th>
              <th @click="refreshData()" style="cursor: pointer">상태 <i class="fa fa-refresh"
                                                                       aria-hidden="true"></i>
              </th>
              <th>-</th>
            </tr>
            <tr v-for="(item,indx) in rechargeList" :key="indx">
              <td>{{ item.createTime|datef('MM/DD HH:mm') }}</td>
              <td>{{ item.amount|comma }}</td>
              <td>
                <span v-if="item.status === sportsConst.APPLY_STATUS_WAITING">미확인</span>
                <span v-if="item.status === sportsConst.APPLY_STATUS_CHECKING">확인중</span>
                <span v-if="item.status === sportsConst.APPLY_STATUS_COMPLITE">완료</span>
                <span v-if="item.status === sportsConst.APPLY_STATUS_CANCEL"
                      style="color: red">실패</span>
              </td>
              <td>
                  <span
                      style="display: inline-block;width: 60px; height: 20px; line-height: 20px;text-align: center;cursor: pointer"
                      v-if="item.status !== sportsConst.APPLY_STATUS_WAITING && item.status
                        !== sportsConst.APPLY_STATUS_CHECKING" @click="deleteById(item.id)">
                     <i class="fa fa-close"></i> 삭제
                  </span>

              </td>
            </tr>
            </thead>
            <tbody>
            </tbody>
          </table>
        </div>

      </div>
    </div>

    <!-- right 메뉴 -->
    <right-bar-comp>
      <!--내정보-->
      <user-info-comp slot="userinfo"></user-info-comp>
      <right-buttons-comp slot="btns"></right-buttons-comp>
      <!--배팅카트-->
      <sports-bet-cart-comp slot="betcart"></sports-bet-cart-comp>
      <right-bar-banner-comp slot="banner"></right-bar-banner-comp>
    </right-bar-comp>

    <foot-comp></foot-comp>
    <mobile-foot-menus></mobile-foot-menus>

  </div>

</template>

<script>
import SubTitle from "../../components/SubTitle";
import RightBarComp from "../../components/RightBarComp";
import {
  changeCoin2Cash,
  deleteApply,
  getApplyCashList, getCoinableStatus, getCoinSiteLoginUrl, getinfotext,
  getTodayRechargeStatus,
  rechargeCash,
  saveQuestion
} from "../../network/userRequest";
import sportsConst from "../../common/sportsConst";
import LeftBarComp from "../../components/LeftBarComp";
import LeisureLeftComp from "../../components/leisuregame/LeisureLeftComp";
import FootComp from "../../components/FootComp";
import TopbarComp from "../../components/TopbarComp";
import RightBarBannerComp from "../../components/RightBarBannerComp";
import {
  RECEIVE_HIDE_LOADING,
  RECEIVE_SHOW_LOADING,
} from "../../store/mutation-types";
import SportsLeftBarComp from "../../components/sports/SportsLeftBarComp";
import {postionMixin} from "../../common/mixin";
import UserInfoComp from "../../components/UserInfoComp";
import RightButtonsComp from "../../components/RightButtonsComp";
import SportsBetCartComp from "../../components/sports/SportsBetCartComp";
import ApplyButtonsComp from "../../components/ApplyButtonsComp";
import WarningSample from "../../components/WarningSample";
import MobileFootMenus from "../../components/MobileFootMenus";

export default {
  name: "Recharge",
  mixins: [postionMixin],
  components: {
    MobileFootMenus,
    WarningSample,
    ApplyButtonsComp,
    SportsBetCartComp,
    RightButtonsComp,
    UserInfoComp,
    SportsLeftBarComp,
    RightBarBannerComp, TopbarComp, FootComp, LeisureLeftComp, LeftBarComp, RightBarComp, SubTitle
  },
  data() {
    return {
      sportsConst,
      infotext: null,
      applyCash: {
        amount: 0,
        bonusin: sportsConst.APPLY_BONUS_YES1,
        consiterecharge: 0,
      },
      applyAmount: '',
      search: {applyType: sportsConst.APPLY_TYPE_RECHARGE},
      rechargeList: [],
      isSampleSite: false,
      casinocash: 0,
      position: "입금",
      b0: null,
      b1: null,
      b2: null,
      b3: null,
      b4: null,
      todaystatus: 0,
      coinable: 0,
    }
  },
  methods: {
    setComma() {
      if (this.applyAmount == '') {
        this.applyAmount = ''
      } else {
        this.applyAmount = this.$root.$options.filters.comma(this.applyAmount)
      }
    },
    changeMoney(cash) {
      if (cash === 0) {
        this.applyAmount = '';
      } else {
        let c = this.applyAmount == '' ? 0 : this.applyAmount.replaceAll(',', '')
        this.applyAmount = this.$root.$options.filters.comma(parseInt(c) + parseInt(cash));

      }
    },
    gotocoinsite() {
      this.$store.commit(RECEIVE_SHOW_LOADING)
      getCoinSiteLoginUrl().then(res => {
        this.$store.commit(RECEIVE_HIDE_LOADING)
        if (res.data.success) {
          let url = res.data.data;
          if (null != url) {
            window.open(url, '_blank');
          }
        } else {
          this.$swal({
            title: res.data.msg,
            type: 'error',
            confirmButtonText: '확인',
            showCancelButton: false,
            showConfirmButton: true
          })
        }

      })
    },
    changeCoin2SiteCash(){
      if (this.applyCash.bonusin === -1) {
        this.$swal({
          title: '보너스 동의를 선택하세요',
          type: 'error',
          confirmButtonText: '확인',
          showCancelButton: false,
          showConfirmButton: true
        })
        return false
      }
      this.$store.commit(RECEIVE_SHOW_LOADING)
      changeCoin2Cash(this.applyCash).then(res=>{
        this.$store.commit(RECEIVE_HIDE_LOADING)
        if (res.data.success) {
          this.$swal({
            title: '전환성공',
            type: 'success',
            confirmButtonText: '확인',
            showCancelButton: false,
            showConfirmButton: true
          })
          this.$store.dispatch('actionUserInfo')
        } else {
          this.$swal({
            title: res.data.msg,
            type: 'error',
            confirmButtonText: '확인',
            showCancelButton: false,
            showConfirmButton: true
          })
        }
      })
    },

    recharge(consiterecharge) {

      this.applyCash.amount = this.applyAmount.replaceAll(',', '');

      this.applyCash.consiterecharge = consiterecharge;
      if (this.applyCash.bonusin === -1) {
        this.$swal({
          title: '보너스 동의를 선택하세요',
          type: 'error',
          confirmButtonText: '확인',
          showCancelButton: false,
          showConfirmButton: true
        })
        return false
      }

      this.$swal({
        title: this.applyCash.amount + '원 충전신청 하시겠습니까?',
        type: 'question',
        confirmButtonText: ' 예 ',
        cancelButtonText: '아니오'
      }).then((res) => {
        this.betInfo = {selectedVals: []}
        if (res.value) {
          this.$store.commit(RECEIVE_SHOW_LOADING)
          rechargeCash(this.applyCash).then(res => {
            if (res.data.success) {
              this.$swal({
                title: '충전신청성공',
                type: 'success',
                confirmButtonText: '확인',
                showCancelButton: false,
                showConfirmButton: true
              })
              this.applyCash.bonusin = sportsConst.APPLY_BONUS_NOT
              this.applyCash.amount = 0
              this.applyCash.consiterecharge = 0;
              this.applyAmount = ''
              this.initRechargeList()
              // if (this.coinable == sportsConst.YES) {
              //   this.$store.commit(RECEIVE_SHOW_LOADING)
              //   getCoinSiteLoginUrl().then(res => {
              //     let url = res.data.data;
              //     console.log(url)
              //     if (null != url) {
              //       window.open(url,'_blank');
              //     }
              //     this.$store.commit(RECEIVE_HIDE_LOADING)
              //   })
              // }
            } else {
              this.$swal({
                title: res.data.msg,
                type: 'error',
                confirmButtonText: '확인',
                showCancelButton: false,
                showConfirmButton: true
              })
            }
            this.$store.commit(RECEIVE_HIDE_LOADING)
          })
        }
      })
    },
    initRechargeList() {
      this.$store.commit(RECEIVE_SHOW_LOADING)
      getApplyCashList(this.search).then(res => {
        if (res.data.success) {
          this.rechargeList = res.data.data
        }
        this.$store.commit(RECEIVE_HIDE_LOADING)
      })
    },
    refreshData() {
      this.initRechargeList()
      this.$bus.$emit('applyRequestSuccess')
    },
    deleteById(id) {
      this.$swal({
        title: '삭제하시겠습니까?',
        type: 'question',
        confirmButtonText: ' 예 ',
        cancelButtonText: '아니오'
      }).then((res) => {
        if (res.value) {
          deleteApply(id).then(res => {
            if (res.data.success) {
              this.initRechargeList()
            } else {
              this.$swal({
                title: res.data.msg,
                type: 'error',
                confirmButtonText: '확인',
                showCancelButton: false,
                showConfirmButton: true
              })
            }
          })
        }
      })
    },
    questioAcNo() {
      this.$store.commit(RECEIVE_SHOW_LOADING)
      let content = '계좌번호 문의합니다'
      saveQuestion({'content': content, 'type': sportsConst.CUSTOMER_TYPE_BANK_CARD}).then(res => {
        this.$store.commit(RECEIVE_HIDE_LOADING)
        if (res.data.success) {
          this.content = ''
          this.$swal({
            title: '계좌번호 문의글 작성이 완료되였습니다',
            type: 'success',
            confirmButtonText: '확인',
            showCancelButton: false,
            showConfirmButton: true
          })
          this.$router.push('/customer_center')

        } else {
          this.$swal({
            title: res.data.msg,
            type: 'error',
            confirmButtonText: '확인',
            showCancelButton: false,
            showConfirmButton: true
          })
        }
      })
    },
    getTodayRechargeStatus() {
      getTodayRechargeStatus().then(res => {
        this.todaystatus = res.data.data
        try {
          if (this.todaystatus == sportsConst.RECHARGE_FIRSTBONUS) {
            let b = this.$store.state.userInfo.rank.rechargebonus1.split(",")
            this.b1 = '(첫충 ' + b[0] + '%)'
            this.b2 = '(첫충 ' + b[1] + '%)'
            this.b3 = '(첫충 ' + b[2] + '%)'
            this.b4 = '(첫충 ' + b[3] + '%)'
            this.b0 = '(첫충 0%)'
          }
          if (this.todaystatus == sportsConst.RECHARGE_DAYBONUS) {
            let b = this.$store.state.userInfo.rank.rechargebonus2.split(",")
            this.b1 = '(매충 ' + b[0] + '%)'
            this.b2 = '(매충 ' + b[1] + '%)'
            this.b3 = '(매충 ' + b[2] + '%)'
            this.b4 = '(매충 ' + b[3] + '%)'
            this.b0 = '(매충 0%)'
          }
          if (this.todaystatus == sportsConst.RECHARGE_NOBONUS) {
            this.applyCash.bonusin = sportsConst.APPLY_BONUS_NOT
          }
        } catch (e) {

        }

      })
    },
    getInfoText() {
      getinfotext().then(res => {
        this.infotext = res.data.data.rechargeText
      })
    }
  },
  created() {
    this.getInfoText()
    this.initRechargeList()
    this.getTodayRechargeStatus()

    getCoinableStatus().then(res => {
      this.coinable = res.data.data;
    })

  },
  mounted() {

  },
  watch: {
    "applyCash.bonusin": {
      handler(newVal) {
      },
      immediate: true,
      deep: false
    },
  }
}
</script>

<style scoped>
@import url("../../assets/css/base.css");
@import url("../../assets/css/common.css");
@import url("../../assets/css/style.css");
@import url("../../assets/css/media.css");
@import url("../../assets/css/sports.css");
@import url("../../assets/css/reex.css");
</style>